// import React from 'react';
// import Div from "@jumbo/shared/Div";
// import Link from "@mui/material/Link";
// import { ASSET_IMAGES } from "../../utils/constants/paths";
// import { Typography } from '@mui/material';


// const Logo = ({ mini, mode, sx }) => {
//     return (
//         <Div sx={{ display: "inline-flex", ...sx }}>
//             <Link href={'/'}>
//                 {
//                     // !mini ?
//                     //     <img src={ mode === "light" ? `${ASSET_IMAGES}/logo.png` : `${ASSET_IMAGES}/logo-white.png`} alt="Jumbo React" />
//                     //     :
//                     //     <img src={mode === "light" ? `${ASSET_IMAGES}/logo-short.png` : `${ASSET_IMAGES}/logo-short-white.png`} alt="Jumbo React" />
//                     //  <img style={{height:"90px"}} src={mode === "light" ? `${ASSET_IMAGES}/clubLogo.png` : `${ASSET_IMAGES}/logo-short-white.png`} alt="Jumbo React" />
//                 }
//             <Typography variant='h2' sx={{fontWeight:"400"}}><span style={{color:"#7352C7"}}>PARIMA</span> <span style={{color:"#475259"}}>EVENT</span></Typography>
//             </Link>
//         </Div>
//     );
// };

// Logo.defaultProps = {
//     mode: "light"
// };

// export default Logo;

import React from "react";
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import { ASSET_IMAGES } from "../../utils/constants/paths";


const Logo = ({ mini, mode, sx }) => {
  return (
    <Div sx={{ display: "inline-flex", ...sx, mt: 2 }}>
      <Link href={"/"}>
        {!mini ? (
          <img
            style={{

              height: "50px",
              width: "150px",
              mixBlendMode: "color-burn",
            }}
            src={
              mode === "light"
                ? `${ASSET_IMAGES}/avpn_logo.png`
                : `${ASSET_IMAGES}/logo-short-white.png`
            }
            alt="Jumbo React"
          />
        ) : (
          <img
            src={
              mode === "light"
                ? `${ASSET_IMAGES}/logo.jfif`
                : `${ASSET_IMAGES}/logo-short-white.png`
            }
            alt="Jumbo React"
          />
        )}

      </Link>

    </Div>
  );
};

Logo.defaultProps = {
  mode: "light",
};

export default Logo;
