import React, { useEffect } from "react";
import {
  Autocomplete,
  Card,
  CardContent,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Swal from "sweetalert2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { Axios } from "app/services/config";
import ToastAlerts from "app/components/Toast";
import { isValidEmail } from "@jumbo/utils";
import { onRoomList } from "app/redux/actions/Room";
import { useState } from "react";
const EditGuest = () => {
  const navigate = useNavigate();
  const showAlert = ToastAlerts();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  // const [gender, SetGender] = useState(["Male", "Female"]);
  const [category, SetCategory] = useState([
    "AVPN",
    "Delegate",
    "Speaker",
    "AVPN Partner",
    "Session Pass",
    "Media",
  ]);
  var initialValues = {
    first_name: state?.first_name,
    last_name: state?.last_name,
    email_id: state?.email_id,
    // gender: state?.gender,
    category: state?.category,
    // mobile_no: state?.mobile_no,
    company_name: state?.company_name,
    qr_code_value: state?.qr_code_value,
    // designation: state?.designation,
    // industry: state?.industry,
    // dob: state?.dob,
    // registration_date: state?.registration_date,
    status: state?.status,
  };

  const validationSchema = yup.object({
    first_name: yup
      .string("Enter First Name")
      .required("First Name is required")
      .matches(
        /^[A-Za-z ]+$/,
        "First Name must contain only alphabetic characters"
      ),
    last_name: yup
      .string("Enter Last Name")
      .required("Last Name is required")
      .matches(
        /^[A-Za-z ]+$/,
        "Last Name must contain only alphabetic characters"
      ),
    // email_id: yup
    //   .string("Enter your Email ID")
    //   .required("Email is required")
    //   .test(
    //     "isValidEmail",
    //     "Email should contain valid characters, an '@' symbol",
    //     (value) => isValidEmail(value)
    //   ),
    // gender: yup.string("Enter your Gender").required("Gender is required"),
    category: yup
      .string("Enter your Badge Label").nullable()
      .required("Badge Label is required"),
    // registration_date: yup
    //   .string("Enter your Registration Date")
    //   .required("Registration Date is required"),

    company_name: yup
      .string("Enter your Organisation")
      .required("Organisation is required"),
    // designation: yup
    //   .string("Enter your Designation")
    //   .required("Designation is required"),
    qr_code_value: yup
      .string("Enter your QR Code Value")
      .required("QR Code Value is required"),
  });

  const handleGuestEdit = async (data, setSubmitting) => {
    try {
      setSubmitting(true);
      await Axios.patch(`/guest/edit/${id}`, data);
      showAlert("success", "Guest update successfully.");
      navigate("/guest");
    } catch (error) {
      showAlert("error", error.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    dispatch(onRoomList());
  }, []);
  return (
    <React.Fragment>
      <Typography variant="h1" mb={3}>
        EDIT GUEST
      </Typography>
      <Card>
        <CardContent>
          <Formik
            validateOnChange={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              validationSchema
                .validate(data, { abortEarly: false })
                .then(() => {
                  console.log("data => ", data)
                  handleGuestEdit(data, setSubmitting);
                })
                .catch((validationErrors) => {
                  console.error("Validation Errors:", validationErrors);
                });
            }}
          >
            {({ setFieldValue, isSubmitting, values, errors, touched }) => (
              <Form noValidate autoComplete="off">

                <Grid container rowSpacing={3} columnSpacing={3}>
                  {/* <Grid item xs={3}>
                    <FormControl
                      key={values?.registration_date}
                      fullWidth
                      error={
                        errors?.registration_date && touched?.registration_date
                      }
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date of Registration *"
                          id="from"
                          format="DD-MM-YYYY"
                          name="from"
                          sx={{
                            width: "100%",
                          }}
                          value={
                            values.registration_date
                              ? dayjs(values.registration_date)
                              : errors.registration_date
                              ? ""
                              : null
                          }
                          onChange={(newValue) => {
                            setFieldValue("registration_date", newValue);
                          }}
                          maxDate={dayjs()} 
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                      {errors.registration_date &&
                        touched.registration_date && (
                          <FormHelperText>
                            {errors.registration_date}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="first_name"
                      name="first_name"
                      label="First Name *"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="last_name"
                      name="last_name"
                      label="Last Name *"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      required
                      id="company_name"
                      name="company_name"
                      label="Organisation"
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="email_id"
                      name="email_id"
                      label="Email *"
                    />
                  </Grid> */}

                  {/* <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      type="number"
                      id="mobile_no"
                      name="mobile_no"
                      label="Mobile No *"
                    />
                  </Grid> */}
                  {/* <Grid item xs={3}>
                    <FormControl
                      fullWidth
                      error={errors.gender && touched.gender}
                    >
                      <Autocomplete
                        fullWidth
                        size="small"
                        disablePortal
                        getOptionLabel={(option) => option}
                        options={gender}
                        value={values?.gender || null}
                        name="gender"
                        onChange={(event, val) => {
                          setFieldValue("gender", val);
                        }}
                        renderInput={(params) => (
                          <TextField
                            error={errors.gender && touched.gender}
                            {...params}
                            label="Gender *"
                          />
                        )}
                      />
                      {errors && errors.gender && touched.gender && (
                        <FormHelperText>{errors.gender}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      error={errors.category && touched.category}
                    >
                      <Autocomplete
                        fullWidth
                        size="small"
                        disablePortal
                        getOptionLabel={(option) => option}
                        options={category}
                        value={values?.category || null}
                        name="category"
                        onChange={(event, val) => {
                          setFieldValue("category", val);
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            error={errors.category && touched.category}
                            {...params}
                            label="Badge Label"
                          />
                        )}
                      />
                      {errors && errors.category && touched.category && (
                        <FormHelperText>{errors.category}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      id="qr_code_value"
                      name="qr_code_value"
                      label="QR Code Value*"
                    />
                  </Grid>

                  {/* <Grid item xs={3}>
                    <FormControl
                      key={values.dob}
                      fullWidth
                      error={errors.dob && touched.dob}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date of Birth *"
                          id="from"
                          format="DD-MM-YYYY"
                          name="from"
                          sx={{
                            width: "100%",
                          }}
                          maxDate={dayjs()}
                          value={
                            values.dob
                              ? dayjs(values.dob)
                              : errors.dob
                              ? ""
                              : null
                          }
                          onChange={(newValue) => {
                            setFieldValue("dob", newValue);
                          }}
                          slotProps={{ textField: { size: "small" } }}
                        />
                      </LocalizationProvider>
                      {errors.dob && touched.dob && (
                        <FormHelperText>{errors.dob}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      required
                      id="industry"
                      name="industry"
                      label="Industry"
                    />
                  </Grid> */}

                  {/* <Grid item xs={6}>
                    <JumboTextField
                      fullWidth
                      required
                      id="designation"
                      name="designation"
                      label="Designation"
                    />
                  </Grid> */}

                  <Grid item xs={12} alignContent="center">
                    <FormControlLabel
                      style={{ padding: "0px", margin: "0px", height: "100%" }}
                      control={
                        <Switch
                          onChange={(e) => {
                            setFieldValue(
                              "status",
                              values?.status ? false : true
                            );
                          }}
                          defaultChecked={values?.status ? true : false}
                          color="primary"
                        />
                      }
                      label="Status"
                      name="status"
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>

                <Grid container columnSpacing={3} mt={5}>
                  <Grid item xs={6} textAlign="right">
                    <LoadingButton
                      variant="contained"
                      size="medium"
                      type="submit"
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6} textAlign="left">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure you want to cancel?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonText: "Yes",
                          cancelButtonText: "No",
                        }).then((result) => {
                          if (result?.isConfirmed) {
                            navigate("/guest");
                          }
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default EditGuest;
